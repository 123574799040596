import React from 'react';
import ReactDOM from 'react-dom/client';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { featureFlagsClient } from './logic/featureFlags';
import App from './App';
import { TelemetryProvider } from '@getvim/opentelemetry-sdk/react';
import { documentViewerLogger } from './logic/logger';
import { AppEnv } from './logic/appEnv';
import { widgetId } from './consts';
import { ErrorBoundaryWrapper } from '@getvim/atomic-ui';

const handleRenderError = ({ error }) => {
  documentViewerLogger.error('Client application crashed due to render error', {
    error,
  });
};

const appEnv: AppEnv = (window as any).$vim_environment?.APP_ENV;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <TelemetryProvider
      options={{
        serviceName: widgetId,
        env: appEnv,
        enableLogs: true,
        enableMetrics: true,
        enableTraces: true,
      }}
    >
      <FeatureFlagProvider featureFlagsClient={featureFlagsClient}>
        <ErrorBoundaryWrapper onRenderError={handleRenderError} applicationDisplayName={widgetId}>
          <App />
        </ErrorBoundaryWrapper>
      </FeatureFlagProvider>
    </TelemetryProvider>
  </React.StrictMode>,
);
